<template>
  <div>
    <general-table
      ref="slidersTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #filter>
        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.is_active"
              value-field="value"
              text-field="text"
              :options="status"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Status
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.is_active">
              <b-button
                size="sm"
                @click="filter.is_active = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.slidersTable.getAllData()"
        >
          Filter
        </b-button>

      </template>
      <template #cell(video)="data">
        <video
          v-if="getFileExtension(data.item.video)=='mp4'"
          id="video-preview"
          controls
          :src="data.item.video"
          width="200"
        />
        <b-img
          v-else
          v-img
          width="200"
          :src="data.item.video"
        />

      </template>
      <template
        #cell(is_active)="data"
      >
        <b-badge
          class="change-status"
          :variant="changeVaraint(getTypes(data.item.is_active))"
        >
          {{ getTypes(data.item.is_active) }}
        </b-badge>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      addType: 'Add Slider',
      addComponentName: 'add-slider',
      editComponent: 'edit-slider',
      viewContent: true,
      type: 'page',
      filter: {},
      status: [
        { text: 'Active', value: '1' },
        // { text: 'Not Active', value: '0' },
      ],
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'video', label: 'File' },
        { key: 'name', label: 'Name' },
        { key: 'is_active', label: 'status' },
        { key: 'actions' },
      ],
    }
  },
  computed: {

    apiUrl() {
      let base = 'admin/sliders?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Active') return 'success'

      return 'danger'
    },
    getFileExtension(fileName) {
      return fileName.split('.').pop().toLowerCase()
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    getTypes(type) {
      if (type === 1) {
        return 'Active'
      } if (type === 0) {
        return 'Not Active'
      }
      return 'Active'
    },
  },
}
</script>

<style>

</style>
